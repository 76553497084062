//import User from "../../assest/user.jpg";

export const Data = [
    {
        id:1,
        //img: User,
        name: "Komal Attarde",
        post: "Android Development Intern",
        Message:"I want to express my heartfelt gratitude for the invaluable internship experience at Flare Global Soft in app development. The mentorship and guidance were exceptional, helping me gain a comprehensive understanding of the field. Thank you for the opportunity and incredible support.",
    },
    

    {
        id:2,
        //img: User,
        name: "Adityaraj Acharya",
        post: "Full Stack Development Intern",
        Message: "I thought the internship's curriculum was good, and the mentor was helpful in clearing up any confusion.",
    },

    {
        id:3,
        //img: User,
        name: "Sanket Shinde",
        post: "Full Stack Development Intern",
        Message: "I loved that week wise partition of topics and weekly mini projects idea. But when backend came, it takes lots of time to understand it So need more time for backend. Our mentor gave it 😄 that's why no issue in it",
    },

];